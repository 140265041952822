import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    -webkit-background-origin: border;
    background-repeat: no-repeat;
    padding-top: 5%
    overflow-x: hidden;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
`

export const H1 = styled.h1`
    font-size: 2.5vw;
    margin: 2%;
`

export const H2 = styled.h2`
    font-size: 2vw; 
    margin: 1%;
`

export const H3 = styled.h3`
    font-weight: 1;
    font-size: 1.7vw;
    margin: 3%;
`

export const H4 = styled.h4`
    font-weight: 1;
    font-size: 1.7vw;
    margin: 0%;
`

export const A = styled.a`
    font-size: 1.7vw;
    margin: 0%; 
`

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #dbdbdb;
    border-radius: 35px;
    width: 40%;
    min-width: 500px;
    opacity: 0.7; 
`
import React, { Component } from 'react'
import { Container, Content, Image } from './NavBarElements'
import windowcareLogo from '../Images/windowcare_logo_black_text.png';

export default class NavBar extends Component {
  render() {
    return (
      <Container>
          <Content>
            <Image src={windowcareLogo}/>
          </Content>
      </Container>
    )
  }
}
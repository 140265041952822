import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs';


export default function App() {
  return (
    <div>
      {<Router>        
        <Routes>
        <Route path="/" caseSensitive={false} element={<Home />} />
        </Routes>
      </Router>}
    </div>
  );
}
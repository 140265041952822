import React, { Component } from "react";
import { Container, Content, Image, H1, H2, H3, H4, A, ListContainer } from './HomeElements';
import NavBar from '../NavBar/NavBar';
import {Grid} from '@mui/material';
import windowcareLogo from '../Images/windowcare_logo_black_text.png';
import people from '../Images/people.jpg'

export default class Home extends Component{
    render(){
        return(
            <Container className="container" style={{ backgroundImage: `url(${people})`}}>
                <NavBar/>
                <Content>
                <ListContainer>
                    <H1>Website under construction</H1>
                    <H2> We are still open though :</H2>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10%'}}>
                        <Grid item xs={5}>
                            <H3>Tuesday</H3>
                        </Grid>
                        <Grid item xs={5}>
                            <H3>By Appointment</H3>
                        </Grid>
                        <Grid item xs={5}>
                            <H3>Thursday - Friday</H3>
                        </Grid>
                        <Grid item xs={5}>
                            <H3>9:30 - 17:30</H3>
                        </Grid>
                    </Grid>
                    <H2>Get in contact: 01204 520883</H2>
                    <H4>Mon-Fri : 9:30-17:30</H4>
                    <H2>or <A href="mailto://sales@windowcare.co.uk">sales@windowcare.co.uk</A></H2>                    
                </ListContainer>
                </Content>
            </Container>
        )
    }
}
import styled from "styled-components";

export const Container = styled.div`
    height: 10vh;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    top: 0;
    z-index: 10;
    position: fixed;
    width: 100%;
`

export const Content = styled.div`
`

export const Image = styled.img`
    width: 40vw;
`